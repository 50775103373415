<template>
    <div class="MyInfo">
        <div class="container-left">
            <div
                class="info click"
                style="justify-content: start"
                @click="toJump(-1, '/myInfo')"
            >
                <div class="nosel mr-9" :class="{ sel: active == -1 }"></div>
                <div :class="{ text: active == -1 }">个人信息</div>
            </div>
            <div class="pad-9">
                <el-divider></el-divider>
            </div>
            <div class="pr-14 pl-14 pb-14">
                <div
                    class="info click"
                    @click="toJump(index, item.url)"
                    v-for="(item, index) in cardList"
                    :key="'card' + index"
                    v-show="!(item.id == 4 && user.teacher == 0)"
                >
                    <div class="flex-alc">
                        <div
                            class="nosel"
                            :class="{ sel: index == active }"
                        ></div>
                        <div
                            class="pt-12 pb-12 pr-9 pl-9"
                            :class="{ text: index == active }"
                        >
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="newsNum" v-if="item.id == 7 && newsNumber > 0">
                        {{ newsNumber }}
                    </div>
                </div>
            </div>
        </div>
        <div class="container-right">
            <router-view v-wechat-title="$route.meta.title"></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyInfo",
        data() {
            return {
                cardList: [
                    {
                        id: 0,
                        name: "我的积分",
                        url: "/myInfo/integral",
                    },
                    {
                        id: 1,
                        name: "收藏夹",
                        url: "/myInfo/favorite",
                    },
                    {
                        id: 2,
                        name: "我的师傅",
                        url: "/myInfo/master",
                    },
                    {
                        id: 3,
                        name: "我的徒弟",
                        url: "/myInfo/apprentice",
                    },
                    {
                        id: 4,
                        name: "讲师中心",
                        url: "/myInfo/lecturer",
                    },
                    {
                        id: 5,
                        name: "学习地图",
                        url: "/myInfo/map?source=0",
                    },
                    {
                        id: 6,
                        name: "学习证书",
                        url: "/myInfo/certificate",
                    },
                    {
                        id: 7,
                        name: "消息中心",
                        url: "/myInfo/news",
                    },
                    {
                        id: 8,
                        name: "我的学习任务",
                        url: "/myInfo/target",
                    },
                ],
            };
        },
        computed: {
            active: function () {
                return this.$store.state.myActive;
            },
            user: function () {
                return this.$store.state.user;
            },
            newsNumber: function () {
                return this.$store.state.newsNum;
            },
        },

        mounted() {
            let active = window.localStorage.getItem("myNavActive") || -1;
            this.$store.commit("switchMyNav", active);
        },

        methods: {
            //标签页切换
            toJump(index, url) {
                window.localStorage.setItem("myNavActive", index);
                this.$store.commit("switchMyNav", index);
                if (index != 5) {
                    window.localStorage.setItem("navActive", 4);
                    this.$store.commit("switchNav", 4);
                }
                this.$router.push({ path: url });
            },
        },
    };
</script>

<style lang="less" scoped>
.MyInfo {
    width: 1200px;
    margin: auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    .container-left {
        width: 213px;
        padding: 31px 8px;
        background: white;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 150px;
        height: max-content;
        border-radius: 10px;
        .el-divider--horizontal {
            margin-top: 4px;
            margin-bottom: 3px;
        }
        .nosel {
            width: 4px;
            height: 20px;
            border-radius: 2px;
        }
        .sel {
            background: linear-gradient(180deg, #ff3764, #ff2604);
        }
        .info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .newsNum {
                width: 16px;
                height: 16px;
                border-radius: 100%;
                background: #ff3143;
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .text {
            color: #e41414;
        }
    }
    .container-right {
        width: 970px;
    }
}
</style>